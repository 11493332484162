import React from 'react';
import '../css/Staff.css';
import img_apple from '../../img/apple.jpeg';
import img_kawano from '../../img/kawano.png';
import img_kouno from '../../img/kouno.jpg'
import img_blueberries from '../../img/blueberries.jpeg';

export const Staff = () => {
    const staff = [
        {
            "name": "河野真士",
            "position": "所長　研究員",
            "img": img_kawano,
            "subject": ["数学科", "国語科（古典, 漢文素読）", "外国語科（ドイツ語, フランス語, イタリア語, 古典ギリシア語, 古典ラテン語）"],
            "entitle": "大分に生まれる。1999年東京大学現役合格, 教養学部前期課程で日本文学・ドイツ文学・哲学・西洋古典学・法学・経済学・生物学・医学などあらゆる学問の基礎を学び、修了要件の２倍の単位を取得して教養学部修了。理学部数学科を卒業。ただし、二度の留年を経験している。卒業講読は「パンルヴェ方程式」（野海正俊著）、「複素幾何」（小林昭七著）。その後、北海道大学にて偏微分方程式論を専攻して博士号（理学）を取得。首都圏のさまざまな大学で数学や物理の非常勤講師を務める。",
            "description": "すべての数学（含：諸科学・諸学問への応用）、文学、哲学に興味があります。勉強ばかりしていて論文を書かないため、研究者としては５流（これから論文書きます）、しかし良い教師ではあるのではないかと自負しています。",
            "works": ["<a href='https://profile.ameba.jp/ameba/shinjikawano1415/'>個人ブログ</a>", "<a href='https://twitter.com/tokyoliberal'>Twitter</a>"]
        },
        {
            "name": "佐々木和美",
            "position": "研究員",
            "img": img_blueberries,
            "subject": ["数学科（〜大学基礎, 数理統計学, 多変量解析, 代数分野, 幾何分野）"],
            "entitle": "広島県の公立高校から東京大学に進学し、理学部数学科を卒業する。トポロジー（位相幾何学「２次元閉多様体上の写像類群のニールセン実現について」）を専攻し、東京大学大学院数理科学研究科修士課程修了後は、３児の母として主婦業をするかたわら大学サークルで覚えたコントラクトブリッジ（トランプゲーム）関連の仕事を行う。子育てが一段落した現在は、再び、家庭教師、塾講師、数学書の校正など、高校・大学時代に学んだ数学を生かした仕事を行なっている。",
            "description": "子育てが落ち着いた後に家庭教師や塾講師を再開して思ったのは、数学科でよかった、ということでした。他の学科であれば、15年以上も前に学んだ知識は古くなり、学び直しが必要だったかもしれません。でも数学では、2500年前に発見されたピタゴラスの定理が古くて使えないとか、正しいと信じられていた定理が新しい学説にとってかわられた、なんてことはありません。数学の真実は永遠に真実。小学校から大学までに学ぶ数学の内容も、昔からほとんど変わりませんし、社会における数学の重要性も古今東西問わず普遍的です。変化や進展の激しい現代に、これは大変珍しいことだと思います。何の資格も持たない私が、子育てのブランクを経ても大学で学んだことを生かして働けるのは、そんな、時を経ても古びることのない数学の恩恵だという気がします。今は、トポロジーと一般相対論や量子論的宇宙論とのつながりが気になっていて、これを仕事にできないかな、などと欲張りなことを考えています。",
            "works": [
                "<a href='https://wakara.co.jp/mathlog/20211004'>トポロジー有名定理その１～ブラウワーの不動点定理～</a>",
                "<a href='https://wakara.co.jp/mathlog/20211006-02'>トポロジー有名定理その２～ジョルダン・ブラウワーの分離定理～</a>",
                "<a href='https://wakara.co.jp/mathlog/20211009'>トポロジー有名定理その３~ボルスク・ウラムの定理~</a>",
                "<a href='https://wakara.co.jp/mathlog/20210302'>微分トポロジー入門－高次元の世界を感じる－</a>",
                "<a href='https://wakara.co.jp/mathlog/20200809-2'>トポロジーって何？～ポアンカレ予想への招待～</a>",
                "石井俊全氏の著作の校閲校正（2015年以降）", 
                "古賀真輝氏「数学の世界地図」の校正"
            ]
        },
        {
            "name": "神野和磨",
            "position": "研究員",
            "img": img_kouno,
            "subject": [],
            "entitle": "埼玉県立浦和高校卒業。理科二類に進学し、現在東京大学理学部在学中。分子生物学を専攻する傍ら、歴史(特に近現代史)にも関心がある。高校時代は『十八史略』『源氏物語』『竹取物語』などを素読し、古文・漢文に親しむ。",
            "description": "実用的なものが求められる時代にあって本当の勉強とは何であろうか、という疑問を漠然と抱いていたところ、ご縁があって河野さん・佐々木さんと知り合いました。それから何度も西八王子に通い、話し合いをする中で形になったのが高尾数学研究所です。私自身、人類の歩んできた歴史の長さ・深遠さに度々圧倒されそうになりつつも、いつかは霧が晴れたように多くの事物がスッと頭の中に入ってくるようになると信じて、勉強を続ける身であります。思いを同じくする方に私たちの活動が届くよう、SNSなどを通じて発信していきたいです。",
            "works": []
        },
    ];
    
    return (
        <div className='staff'>
            <h1>研究員紹介</h1>
            <ul>
                {
                    staff.map(e => (
                        <li className="profile">
                            <div className='profile-name'>
                                <h2>{e.name}</h2>
                                <p>{e.position}</p>
                            </div>
                            <div className="profile-top">
                                <img src={e.img}/>
                                <div class="profile-top-right">
                                    {/* <h3>担当</h3>
                                    {
                                        e.subject.map((i)=>(
                                            <p style={{padding: 0}}>{i}</p>
                                        ))
                                    } */}
                                    <h3>略歴</h3>
                                    <p style={{ whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: e.entitle }} />
                                    <h3>研究員より一言</h3>
                                    <p>{e.description}</p>
                                    <h3>参考</h3>
                                    {
                                        e.works.map((i)=>(
                                            <p style={{ padding: 0, whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: i }} />
                                        ))
                                    }
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}