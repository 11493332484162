import '../css/BlogArticle.css'
import { useEffect, useState } from 'react';
import { createClient } from 'microcms-js-sdk'; //ES6
import { useLocation, useNavigate } from 'react-router-dom';

const BlogArticle = () => {
    const [article, setArticle] = useState({})
    const location = useLocation()
    const navigate = useNavigate()


    useEffect(()=>{
        const match = location.pathname.match(/\/([^/]+)$/);
        console.log(match)
        let paramVlogId
    
        if (match) {
            paramVlogId = Number(match[1]);
        } else {
            console.log("指定された文字列には二つ目の '/' 以下の部分がありません。");
        }

        const client = createClient({
            serviceDomain: "takao-math", // YOUR_DOMAIN is the XXXX part of XXXX.microcms.io
            apiKey: "ds5HQfEcB5Zf7HVjsPkJnB44ip1lQvrOp7zQ",
            // retry: true // Retry attempts up to a maximum of two times.
            // customFetcher: fetch.bind(globalThis), // Provide a custom `fetch` implementation as an option
        });
    
        client
        .getList({
            endpoint: 'blogs',
            contentId: match,
        })
        .then((res) => { 
            console.log(res)
            setArticle(res.contents[0])
        })
        .catch((err) => console.error(err));
    },[])

    console.log(article)

    return (
        article.eyecatch && 
        <div className="blog-article">
            <a className="back-to-blog" href="../blog">←ブログ一覧へ戻る</a>
            <div className='background-white'>
                <h1>{article.title}</h1>
                <div className='img'>
                    <img src={article.eyecatch.url}></img>
                </div>
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </div>
        </div>
    )
}

export default BlogArticle