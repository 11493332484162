import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import "./Footer.css"
import { AiFillFacebook, AiFillTwitterSquare, AiOutlineInstagram } from "react-icons/ai"


export const Footer = () => {
    return(
        <footer>
            <div className='footer-left'>
                <ul>
                    <Link to="">トップ</Link>
                    <Link to="seminar">過去の活動</Link>
                    <Link to="course">Liberal Artsとは</Link>
                    <Link to="member">研究員紹介</Link>
                    <Link to="blog">所員ブログ</Link>
                    <Link to="contact">お問合せ</Link>
                    <a target="blank" href="サークル規約・高尾数学研究所_2024_0118.pdf">会員規約</a>
                </ul>
            </div>
            <div className='footer-right'>
                <p className='company-name'>高尾数学研究所</p>
                <div className='icons'>
                    <a href='' target='blank'><AiFillFacebook className='icon'/></a>
                    <a href='https://twitter.com/tokyoliberal' target='blank'><AiFillTwitterSquare className='icon'/></a>
                    <a href='https://www.instagram.com/takao_math/' target='blank'><AiOutlineInstagram className='icon'/></a>
                </div>
                <p className='address'>東京都八王子市</p>
                <p className='tel'>080-4584-6745</p>
                <p className='e-mail'>takao.math.contact@gmail.com</p>
            </div>
            {/* <ul>
                <li><Link to="/">ホーム</Link></li>
                <li><Link to="/about">教育理念</Link></li>
                <li><Link to="/course">コース紹介</Link></li>
                <li><Link to="/staff">講師紹介</Link></li>
                <li><Link to="/application">体験申し込み</Link></li>
                <li><Link to="/contact">お問合せ</Link></li>
            </ul> */}
        </footer>
    )
}