import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { init, send } from 'emailjs-com';
import { Button } from "@mui/material";
import '../css/Contact.css';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export const Contact = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;

    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [kana, setKana] = useState('');
    const [mail, setMail] = useState('');
    const [message, setMessage] = useState('');

    const publicKEY = process.env.REACT_APP_PUBLIC_KEY;
    const serviceID = process.env.REACT_APP_SERVICE_ID_CONTACT;
    const templateID = process.env.REACT_APP_TEMPLATE_ID_CONTACT;

    const [hasSent, setHasSent] = useState('');
    const [isError, setIsError] = useState('');
    const [isLoading, setIsLoading] = useState('');

    const disableSend = type ==='' || name === '' || kana === '' || mail === '' || message === '';

    const sendMail = () => {
        if (
            publicKEY !== undefined &&
            serviceID !== undefined &&
            templateID !== undefined &&
            !disableSend
        ) {
            init(publicKEY);
        
            const template_param = {
                type: type,
                name: name,
                kana: kana,
                email: mail,
                message: message,
            };

            emailjs.send(serviceID, templateID, template_param, publicKEY).then(() => {                              
                setIsError(false);
                setHasSent(true);
                setType('');
                setName('');
                setKana('');
                setMail('');
                setMessage('');
              });
        }   
    }

    const handleClick = (e) => {
        setIsLoading(true)
        if(disableSend){
            setIsError(true);
        }
        e.preventDefault();
        sendMail();
        setIsLoading(false);
    };

    return (
        <div className='contact'>
            <h1>お問合せ</h1>
            <p>
                当研究所についてのご質問・ご依頼等ございましたら下記のフォームを入力の上送信してください。<br></br>
                お問合せが提出されると、入力いただいたアドレスに自動返信にて確認のメールが届きます。<br></br>
                確認次第、次のメールアドレスよりご連絡を差し上げます（<a style={{color: '#808080'}} href='mailto:takao.math.contact@gmail.com'>takao.math.contact@gmail.com</a>）
            </p>
            <form>
                <FormControl>
                    <FormLabel id="radio-buttons-group-label">ご要件 <span style={{color: "red"}}>*</span></FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue=""
                        name="radio-buttons-group"
                        onChange={(e)=> setType(e.target.value)}
                    >
                        <FormControlLabel value="セミナー見学のお問い合わせ" control={<Radio sx={{m: '20px'}} variant="outlined"/>} label="セミナー見学のお問い合わせ" />
                        <FormControlLabel value="当研究所に所属する研究員による個別指導についてのお問い合わせ" control={<Radio sx={{m: '20px'}} variant="outlined"/>} label="当研究所に所属する研究員への指導依頼" />
                        <FormControlLabel value="イベント出演や講師派遣などのお問い合わせ" control={<Radio sx={{m: '20px'}} variant="outlined"/>} label="イベント出演や講師派遣などのお問い合わせ" />
                        <FormControlLabel value="その他" control={<Radio sx={{m: '20px'}} variant="outlined"/>} label="その他" />
                    </RadioGroup>
                </FormControl>
                <div className="grid-container">
                    <div className='item-name grid-item'>
                        <div>
                            <label htmlFor="nameForm">お名前 <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="text" id="nameForm" className="formInput" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </div>
                    <div className='item-kana grid-item'>
                        <div>
                            <label htmlFor="kanaForm">フリガナ <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="text" id="kanaForm" className="formInput" value={kana} onChange={(e) => setKana(e.target.value)} required />
                    </div>
                    <div className="item-email grid-item">
                        <div>
                            <label htmlFor="mailForm">メールアドレス <span style={{color: "red"}}>*</span></label>
                        </div>
                        <input type="email" id="mailForm" className="formInput" value={mail} onChange={(e) => setMail(e.target.value)} required/>
                    </div>
                    <div className="item-content grid-item">
                        <div>
                            <label htmlFor="mailContentForm">内容 <span style={{color: "red"}}>*</span></label>
                        </div>
                        <textarea type="text" id="mailContentForm" className="formInput formInputMessage" value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                </div>
                {
                    hasSent ? (
                        <div>
                            <p><b>お問い合わせが送信されました。<br />入力いただいたメールアドレスに確認メールが届いたことをご確認ください。</b></p>
                        </div>
                    ) : undefined
                }
                {
                    isError ? (
                        <div>
                            <p style={{textAlign: 'center'}}><span style={{color: "red"}}><b>必須項目を全て入力してください。</b></span></p>
                        </div>
                    ) : undefined
                }
                <div className="btns">
                    <div>
                        {
                            isLoading ? 
                                <div className="loading__icon"></div>
                            : hasSent ? 
                                <button className="hasSent">
                                    送信完了✓
                                </button>
                            : 
                                <Button variant="contained" onClick={handleClick} className='unSent'>
                                    送信
                                </Button>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}