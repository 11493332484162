import React, { useState } from 'react';
import {NavLink, Link} from 'react-router-dom';
import "./Navbar.css";
import icon_mt from '../img/icon_mountain.png';
import icon_faq from '../img/icon_faq.png';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; 
import img_signature_black from '../img/signature_black.png'

export const Navbar = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;
    const [isClosed, setClosed] = useState(true)

    const showSideBar = () => {
        isClosed ? setClosed(false) : setClosed(true)
    }

    const pages = [
        {
            "dir": "/",
            "name": "トップ"
        },
        // {
        //     "dir": "about",
        //     "name": "教育理念"
        // },
        {
            "dir": "seminar",
            "name": "過去の活動"   
        },
        {
            "dir": "/course",
            "name": "Liberal Artsとは"
        },
        {
            "dir": "/member",
            "name": "研究員紹介"
        },
        {
            "dir": "/blog",
            "name": "ブログ"
        },
        {
            "dir": "/contact",
            "name": "お問合せ"
        },
        // {
        //     "dir": "/faq",
        //     "name": "よくあるご質問"
        // }
    ]

    return(
        <header>
                <nav className='pc'>
                        <div className="nav-left">
                        <Link to="/"><img className="signature" src={img_signature_black} alt="" /></Link>
                        <Link to="/"><img className="icon_mt" src={icon_mt} alt="" /></Link>
                        </div>
                        <ul>
                            {
                                pages.map(e => e.name == "よくあるご質問" ? (<></>) : (
                                    <li><NavLink to={e.dir}>{e.name}</NavLink></li>
                                ))
                            }
                            {/* <li><NavLink to="/faq"><img className="icon_faq" src={icon_faq} alt=""/></NavLink></li> */}
                        </ul>
                </nav>
                <nav className='mobile'>
                    <div className="nav-left">
                    <Link onClick={() => setClosed(true)} to="/"><img className="signature" src={img_signature_black} alt="" /></Link>
                    <Link onClick={() => setClosed(true)} to="/"><img className="icon_mt" src={icon_mt} alt="" /></Link>
                    </div>
                    <IconButton className='menu-button' onClick={() => showSideBar()}>
                        {
                            isClosed ? (
                                <MenuIcon style={{color: 'black'}}/>
                            ) : (
                                <CloseIcon style={{color: 'black'}}/>
                            )
                        }
                    </IconButton>
                    <div className='side-bar' style={isClosed ? {width: '0vw', overflow: 'hidden'} : {width: '100vw'}}>
                        <ul>
                            {
                                pages.map(e => (
                                    <li style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}><NavLink to={`${e.dir}`} onClick={() => showSideBar()}>{e.name}</NavLink></li>
                                ))
                            }
                            <li><a target="blank" href="サークル規約・高尾数学研究所_2024_0118.pdf">会員規約</a></li>
                        </ul>
                    </div>
                </nav>
        </header>
    )
}