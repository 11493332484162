import React from 'react';
import '../css/Course.css';
import Table from '../Table';
import img_latex from '../../img/course-latex-mapping.png'
import eckermann from '../../img/book/eckermann.png'
import dostoevsky from '../../img/book/dostoevsky.png'
import finance from '../../img/book/finance.png'
import gnosis from '../../img/book/gnosis.png'
import idutsu from '../../img/book/idutsu.png'
import iliad from '../../img/book/iliad.png'
import le_miserable from '../../img/book/le_miserable.png'
import manifold from '../../img/book/manifold.png'
import plato from '../../img/book/plato.png'
import renaissance from '../../img/book/renaissance.png'
import sherlock from '../../img/book/sherlock.png'
import sugiura from '../../img/book/sugiura.png'
import topology from '../../img/book/topology.png'
import wagner from '../../img/book/wagner.png'
import statistics from '../../img/book/statistics.png'
import taoism from '../../img/book/taoism.png'
import algebra from '../../img/book/algebra.png'
import mishima from '../../img/book/mishima.png'


export const Course = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;


    return (
        <div className='course'>
            <h1>Liberal Artsとは</h1>
            <p>
                高尾数学研究所では、数学・哲学を基軸に、学問の垣根を超えた研究活動を行います。<br></br><br></br>
                ここでいう研究活動とは、研究所構成員が個々人で勉強をする傍ら、
                定期的なセミナー実施による相互の学びあいをすることのことを意味します。<br></br><br></br>
                また研究員の専門とする学問はさまざまであり、
                数学・哲学・文学・古典語・音楽・生物学・歴史学をはじめとして分野を超えた
                探求を行なっています。<br></br><br></br>
                古代ギリシアでプラトンが運営した学園アカデメイアは無償で、学費はプラトンが傍らで経営するぶどう農園によって賄われていました。<br></br><br></br>
                高尾数学研究所も開かれた学びの場とするため、研究員は場所代としてのわずかな年会費のみで活動を行っています。
            </p>
        </div>
    )
}