import '../css/Blog.css'
import { useEffect, useState } from 'react';
import { createClient } from 'microcms-js-sdk'; //ES6
import { useLocation, useNavigate } from 'react-router-dom';

const Blog = () => {
    const [blogs, setBlogs] = useState([])
    const [selectedBlog, setSelectedBlog] = useState({})
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(()=>{
        // Initialize Client SDK.
        const client = createClient({
            serviceDomain: "takao-math", // YOUR_DOMAIN is the XXXX part of XXXX.microcms.io
            apiKey: "ds5HQfEcB5Zf7HVjsPkJnB44ip1lQvrOp7zQ",
            // retry: true // Retry attempts up to a maximum of two times.
            // customFetcher: fetch.bind(globalThis), // Provide a custom `fetch` implementation as an option
        });
    
        client
        .getList({
          endpoint: 'blogs',
        })
        .then((res) => { 
            console.log(res)
            setBlogs(res.contents)
        })
        .catch((err) => console.error(err));
    },[])

    useEffect(()=>{
        const match = location.pathname.match(/\/([^/]+)$/);
        console.log(match)
        let paramVlogId
    
        if (match) {
            paramVlogId = Number(match[1]);
        } else {
            console.log("指定された文字列には二つ目の '/' 以下の部分がありません。");
        }
        setSelectedBlog(blogs.find(e => e.id === match))
    },[])

    console.log(selectedBlog)

    return (
        <div className="blog">
            <h1>所員ブログ</h1>
            <ul>
                {
                    blogs.map(blog => {
                        const date = new Date(blog.updatedAt)
                            
                        return (
                            <li onClick={e => navigate(`/blog/${blog.id}`)}>
                                <img src={blog.eyecatch.url}></img>
                                <div>
                                    <h2>{blog.title}</h2>
                                    <p>{`${date.getFullYear()}年 ${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours()}時${('0'+date.getMinutes()).slice(-2)}分`}</p>
                                </div>
                            </li>
                        )})
                }
            </ul>
        </div>
    )
}

export default Blog