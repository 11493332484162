import React, {useState, useEffect, useRef} from 'react';
import { Box, Modal } from "@mui/material";
import '../css/Home.css';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import img_seminar_1 from '../../img/seminar_tr1.png'
import img_seminar_2 from '../../img/seminar_tr2.png'
import img_seminar_3 from '../../img/seminar_tr3.png'
import img_seminar_4 from '../../img/seminar_tr4.png'
import img_seminar_5 from '../../img/seminar_tr5.png'
import img_seminar_6 from '../../img/seminar_tr6.png'
import img_seminar_7 from '../../img/seminar_tr7.png'
import img_kawano from '../../img/kawano.png';
import img_kouno from '../../img/kouno.jpg'
import img_blueberries from '../../img/blueberries.jpeg';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import '../css/Calender.css'
import { useInView } from 'react-intersection-observer';

export const Home = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;

    const [isEntered, setIsEntered] = useState((sessionStorage.getItem('isEntered') == "null" || sessionStorage.getItem('isEntered') == undefined) ? false : true)

    const [cursorX, setCoursorX] = useState(false);
    const [cursorY, setCoursorY] = useState(false);

    const navigate = useNavigate();

    const images = [img_seminar_1, img_seminar_2, img_seminar_3, img_seminar_4, img_seminar_5, img_seminar_6,img_seminar_7]

    const [currentImage, setCurrentImage] = useState(images.slice(0,3));

    const interval = 6000;

    useEffect(() => {
        let index = 0
        const intervalId = setInterval(() => {        
            index++
            // setCurrentImage(images[(index)%images.length]);
            setCurrentImage(index%2 == 0 ? images.slice(3,6) : images.slice(0,3));
        }, interval);
    
        return () => clearInterval(intervalId);
    }, []);


    window.addEventListener('mousemove', e => {
        setCoursorX(e.clientX);
        setCoursorY(e.clientY)
    })

    const seminar = [
        {
            "firstTitle": "半線形楕円型方程式の解構造について",
            "firstDetail": "無料です。興味のある方はどなたでも聴講できます。講演者募集。広く、数学、理論物理学、経済学、哲学などについて。最新の研究発表のみでなく、論文紹介や、学部後半の講義レベルでも構いません。仲間になりましょう！！",
            "secondTitle": "第1回 Nothing Seminar",
            "secondDetail": "本研究所で最も大切にしている教育用セミナーです。年齢や身分やレベルに関係なく、勉強していることの成果報告、質疑応答など、を行います。原則的に「受講者側の発信」から始まります。幼稚園レベルから大学院レベルまで、年齢に関係なく学びます。そこから発展的に説明したりすることがあります。",
            "target": "数学、哲学に興味のある方ならどなたでも",
            "date": "<span>10</span>月<span>9</span>日（月・祝）",
            "firstDate": "10/9(月)13:00-15:00",
            "secondDate": "10/9(月)15:00-17:00",
            "speaker": "河野真士",
            // "place": "台町市民センター（西八王子駅）<br /><span>（最寄り：西八王子駅）</span>",
            "place": "JR西八王子駅最寄り徒歩10分",
            "upto": "制限なし",
            "fee": "無料",
            "due": "10/5"
        },
        {
            "firstTitle": "向き付け可能な閉曲面の写像類群の表示",
            "firstDetail": "種数gの閉曲面をg個の円周で切り開くと球面に2g個の穴を開けたものになりますが、このことと写像類群の元の円周への作用、およびある「単純な動き」に着目することで、写像類群の有限表示を得ることができます。<br/>(A.Hatcher and W.Thurstonの論文紹介を予定しています。)",
            "caption": "* 可微分多様体の自己同相写像のイソトピー類のなす群を写像類群と言い、 曲面の場合はとくに（タイヒミュラー）モジュラー群とも呼びます。",
            "secondTitle": "第2回 Nothing Seminar",
            "secondDetail": "本研究所で最も大切にしている教育用セミナーです。年齢や身分やレベルに関係なく、勉強していることの成果報告、質疑応答など、を行います。原則的に「受講者側の発信」から始まります。幼稚園レベルから大学院レベルまで、年齢に関係なく学びます。そこから発展的に説明したりすることがあります。",
            "target": "数学、哲学に興味のある方ならどなたでも",
            "date": "<span>11</span>月<span>3</span>日（金・祝）",
            "firstDate": "11/3(金)13:00-15:00",
            "secondDate": "11/3(金)15:00-17:00",
            "speaker": "佐々木和美",
            // "place": "台町市民センター（西八王子駅）<br /><span>（最寄り：西八王子駅）</span>",
            "place": "JR西八王子駅最寄り徒歩10分",
            "upto": "先着8名まで",
            "fee": "1,000円（資料代込み）",
            "due": "10/19"
        },
        {
            "firstTitle": "外部領域における,半線形楕円型方程式に対する解の存在と非存在について",
            "firstDetail": "本講演では,全空間 Rn における半径 R の球の外部領域上での,半線形楕円型方程式 ∆u + K(r)f(u) = 0 の球対称解 (u(x) = u(|x|) を満たす解) について着目していきます.特に,R が十分大きいときの解の非存在について示すことが主な目標となります.",
            "caption": "* EJDE 2016 J.A.Iaia 氏の論文紹介",
            "secondTitle": "第3回 Nothing Seminar",
            "secondDetail": "本研究所で最も大切にしている教育用セミナーです。年齢や身分やレベルに関係なく、勉強していることの成果報告、質疑応答など、を行います。原則的に「受講者側の発信」から始まります。幼稚園レベルから大学院レベルまで、年齢に関係なく学びます。そこから発展的に説明したりすることがあります。",
            "target": "数学、哲学に興味のある方ならどなたでも",
            "date": "<span>11</span>月<span>23</span>日（木・祝）",
            "firstDate": "11/23(木)13:00-15:00",
            "secondDate": "11/23(木)15:00-17:00",
            "speaker": "間庭正明",
            // "place": "台町市民センター（西八王子駅）<br /><span>（最寄り：西八王子駅）</span>",
            "place": "JR西八王子駅最寄り徒歩10分",
            "upto": "先着8名まで",
            "fee": "1,000円（資料代込み）",
            "due": "11/20"
        },
        {
            "firstTitle": "シャファレヴィッチ−テイト集合について",
            "firstDetail": `次の論説を紹介する。小野孝「シャファレヴィッチ−テイト集合について」別冊・数理科学2000年10月「数論の歩み　未解決問題への挑戦」( 元は1998年に早稲田大学で行われた国際集会「Class Field Theory-its Centenary and Prospect」における同氏の講演「On Shafarevich-Tate Sets」である。)<br>なお、以下概要を述べるが、この論説に書いてある以上の知見は、講演者にはないことを断っておく。<br>シャファレヴィッチ−テイト集合(以降ST集合)は、類体論やHasseの原理に触発されて思い付かれたもののようであるが、定義は群論の初歩の知識のみでできる。「あるクラスの有限群に対してはST集合はtrivialである」という小野孝氏の98年の結果の簡単な場合を証明まで含めて述べる。またtrivialにならない具体例も紹介する。<br>群の定義から述べるため、必要な予備知識は集合の簡単な知識のみである。`,
            "caption": "",
            "secondTitle": "小学校計算やりなおし～数の厳密な構成まで",
            "secondDetail": `分数のできない理系大学生に多く接しています。普通の人は恐らく大半の人が分数の計算は忘れているのではないでしょうか。論理的には、分数より負の数が先です。分数は難しいのです。この講座では、計算法を復習しながら、抽象代数学への入門を兼ねます。目標は名著「数の概念」高木貞治著を読破することです。数は、自然数も、分数も、実数も、本当は難しいんです！平方根の存在証明は大学レベルです。興味がある方の聴講を歓迎します。対象者：中学生以上、教師、学び直したいすべての大人。`,
            "target": "数学、哲学に興味のある方ならどなたでも",
            "date": "12月17日(日) 13:00 - 17:00",
            "firstDate": "12/17(日)13:00-15:00",
            "secondDate": "12/17(日)15:00-17:00",
            "speaker": "河野真士",
            // "place": "台町市民センター（西八王子駅）<br />（最寄り：西八王子駅）",
            "place": "JR西八王子駅最寄り徒歩10分",
            "upto": "先着8名まで",
            "fee": "1,000円（資料代込み）",
            "due": "11/20"
        },
        {
            "firstTitle": "解無数の連立方程式の解法",
            "firstDetail": `大学１年次の必須項目として「解無数の連立方程式を解いてパラメタ表示する」というものがある。きちんと行基本変形して、移項して、パラメタ設定するもので、初級レベルの到達点とも言えるものである。川嶌俊雄氏の教科書(東京海洋大学越中島キャンパスでたまたま指定された教科書)に書かれてある「列基本変形による方法」を紹介する。この方法は、計算の手順がひとつ少なく(移項する必要がない)、さらに計算自体も「最後までしなくてよい」ため、さらに楽である。また、この方法による解は従来の方法による解と、「着目する文字が同じ」ならば、定数倍まで含めていつでも完全に一致することが最近講演者により証明された。そのことを話す。 (プレプリントを配布します。)
            もしかしたら明らかかもしれないため、多数の聴講者と忌憚ない意見を歓迎します。`,
            "secondTitle": "小平邦彦「幾何への誘い」を読む",
            "secondDetail": `日本が生んだ世界的な数学者、小平邦彦氏が中学生レベルの初等幾何を丁寧に書いた教科書。 初等幾何は直感的な右脳パズルの面もあれば、論理の練習の面もあり、教育効果が非常に高いものです。「論理的に読む」訓練は、ユークリッド以来、ここで行うのです。(最近は「契約書」とかを国語で読ませるらしいです。そんなのは面白くもなければ論理の訓練にもならない。そんなことをする位なら国語という科目の中核は初等幾何であるべきです。 )この本を 受講者で輪講するのがよいのですが、初回はお手本(?)として河野が話します。
            対象者：中学生以上、教師、学び直したいすべての大人。`,
            "caption": "",
            "target": "数学、哲学に興味のある方ならどなたでも",
            "date": "2024年1月14日(日) 13:00-17:00",
            "firstDate": "1/14(日)13:00-15:00",
            "secondDate": "1/14(日)15:00-17:00",
            "speaker": "河野真士",
            // "place": "台町市民センター（西八王子駅）<br />（最寄り：西八王子駅）",
            "place": "JR西八王子駅最寄り徒歩10分",
            "upto": "先着8名まで",
            "fee": "1,000円（資料代込み）",
            "due": "11/20"
        },
    ]

    const staff = [
        {
            "name": "河野真士",
            "position": "所長　研究員",
            "img": img_kawano,
            "subject": ["数学科", "国語科（古典, 漢文素読）", "外国語科（ドイツ語, フランス語, イタリア語, 古典ギリシア語, 古典ラテン語）"],
            "entitle": "大分に生まれる。1999年東京大学現役合格, 教養学部前期課程で日本文学・ドイツ文学・哲学・西洋古典学・法学・経済学・生物学・医学などあらゆる学問の基礎を学び、修了要件の２倍の単位を取得して教養学部修了。理学部数学科を卒業。ただし、二度の留年を経験している。卒業講読は「パンルヴェ方程式」（野海正俊著）、「複素幾何」（小林昭七著）。その後、北海道大学にて偏微分方程式論を専攻して博士号（理学）を取得。首都圏のさまざまな大学で数学や物理の非常勤講師を務める。",
            "description": "すべての数学（含：諸科学・諸学問への応用）、文学、哲学に興味があります。勉強ばかりしていて論文を書かないため、研究者としては５流（これから論文書きます）、しかし良い教師ではあるのではないかと自負しています。",
            "works": ["<a href='https://profile.ameba.jp/ameba/shinjikawano1415/'>個人ブログ</a>", "<a href='https://twitter.com/tokyoliberal'>Twitter</a>"],
            "major": "数学(楕円型微分方程式)・哲学",
            "title": '理学博士'
        },
        {
            "name": "佐々木和美",
            "position": "研究員",
            "img": img_blueberries,
            "subject": ["数学科（〜大学基礎, 数理統計学, 多変量解析, 代数分野, 幾何分野）"],
            "entitle": "広島県の公立高校から東京大学に進学し、理学部数学科を卒業する。トポロジー（位相幾何学「２次元閉多様体上の写像類群のニールセン実現について」）を専攻し、東京大学大学院数理科学研究科修士課程修了後は、３児の母として主婦業をするかたわら大学サークルで覚えたコントラクトブリッジ（トランプゲーム）関連の仕事を行う。子育てが一段落した現在は、再び、家庭教師、塾講師、数学書の校正など、高校・大学時代に学んだ数学を生かした仕事を行なっている。",
            "description": "子育てが落ち着いた後に家庭教師や塾講師を再開して思ったのは、数学科でよかった、ということでした。他の学科であれば、15年以上も前に学んだ知識は古くなり、学び直しが必要だったかもしれません。でも数学では、2500年前に発見されたピタゴラスの定理が古くて使えないとか、正しいと信じられていた定理が新しい学説にとってかわられた、なんてことはありません。数学の真実は永遠に真実。小学校から大学までに学ぶ数学の内容も、昔からほとんど変わりませんし、社会における数学の重要性も古今東西問わず普遍的です。変化や進展の激しい現代に、これは大変珍しいことだと思います。何の資格も持たない私が、子育てのブランクを経ても大学で学んだことを生かして働けるのは、そんな、時を経ても古びることのない数学の恩恵だという気がします。今は、トポロジーと一般相対論や量子論的宇宙論とのつながりが気になっていて、これを仕事にできないかな、などと欲張りなことを考えています。",
            "works": [
                "<a href='https://wakara.co.jp/mathlog/20211004'>トポロジー有名定理その１～ブラウワーの不動点定理～</a>",
                "<a href='https://wakara.co.jp/mathlog/20211006-02'>トポロジー有名定理その２～ジョルダン・ブラウワーの分離定理～</a>",
                "<a href='https://wakara.co.jp/mathlog/20211009'>トポロジー有名定理その３~ボルスク・ウラムの定理~</a>",
                "<a href='https://wakara.co.jp/mathlog/20210302'>微分トポロジー入門－高次元の世界を感じる－</a>",
                "<a href='https://wakara.co.jp/mathlog/20200809-2'>トポロジーって何？～ポアンカレ予想への招待～</a>",
                "石井俊全氏の著作の校閲校正（2015年以降）", 
                "古賀真輝氏「数学の世界地図」の校正"
            ],
            "major": "数学(トポロジー)",
            "title": '数理科学修士'
        },
        {
            "name": "神野和磨",
            "position": "研究員",
            "img": img_kouno,
            "subject": [],
            "entitle": "埼玉県立浦和高校卒業。理科二類に進学し、現在東京大学理学部在学中。分子生物学を専攻する傍ら、歴史(特に近現代史)にも関心がある。高校時代は『十八史略』『源氏物語』『竹取物語』などを素読し、古文・漢文に親しむ。",
            "description": "実用的なものが求められる時代にあって本当の勉強とは何であろうか、という疑問を漠然と抱いていたところ、ご縁があって河野さん・佐々木さんと知り合いました。それから何度も西八王子に通い、話し合いをする中で形になったのが高尾数学研究所です。私自身、人類の歩んできた歴史の長さ・深遠さに度々圧倒されそうになりつつも、いつかは霧が晴れたように多くの事物がスッと頭の中に入ってくるようになると信じて、勉強を続ける身であります。思いを同じくする方に私たちの活動が届くよう、SNSなどを通じて発信していきたいです。",
            "works": [],
            "major": "生化学・歴史",
            "title": '学部学生'
        },
    ];

    const notification = [
        {
            date: new Date(2023, 10, 9),
            title: '数学専門セミナー『半線形楕円型方程式の解構造について』を行いました。',
            content: ''
        },
        {
            date: new Date(2023, 11, 3),
            title: '数学専門セミナー『向きつけ可能な閉曲面での写像類群の表示』を行いました。',
            content: ''
        },  
        {
            date: new Date(2023, 11, 23),
            title: '数学専門セミナー『外部領域における、半線形楕円型方程式に対する解の存在と非存在について』を行いました。',
            content: ''
        },
        {
            date: new Date(2023, 12, 17),
            title: '数学専門セミナー『シャファレヴィッチ−テイト集合』を行いました。',
            content: ''
        },
        {
            date: new Date(2024, 1, 1),
            title: 'Webサイトをリニューアルしました。',
            content: ''
        }
    ]

    const events = [
        {
            title:'数学専門セミナー(対面)', 
            start: new Date(2023, 10-1, 9, 13, 0), 
            end: new Date(2023, 10-1, 9, 17, 0), 
            extendedProps: [{
                theme: '半線形楕円型方程式の解構造について',
                detail: '無料です。興味のある方はどなたでも聴講できます。講演者募集。広く、数学、理論物理学、経済学、哲学などについて。最新の研究発表のみでなく、論文紹介や、学部後半の講義レベルでも構いません。仲間になりましょう！！',
                speaker: "河野真士",
                archive: "https://www.youtube.com/embed/Y7_Q3i02254",
                place: '台町市民センター（西八王子駅）'
            }],
        },
        {
            title:'数学専門セミナー(対面)', 
            start: new Date(2023, 11-1, 3, 13, 0), 
            end: new Date(2023, 11-1, 3, 17, 0), 
            extendedProps: [{
                theme: '向きつけ可能な閉曲面での写像類群の表示',
                detail: '種数gの閉曲面をg個の円周で切り開くと球面に2g個の穴を開けたものになりますが、このことと写像類群の元の円周への作用、およびある「単純な動き」に着目することで、写像類群の有限表示を得ることができます。(A.Hatcher and W.Thurstonの論文紹介を予定しています。) * 可微分多様体の自己同相写像のイソトピー類のなす群を写像類群と言い、 曲面の場合はとくに（タイヒミュラー）モジュラー群とも呼びます。',
                speaker: "佐々木和美",
                archive: "https://www.youtube.com/embed/sWxx84I0HtA",
                place: '台町市民センター（西八王子駅）'
            }],
        },
        {
            title:'数学専門セミナー(対面)', 
            start: new Date(2023, 11-1, 23, 13, 0), 
            end: new Date(2023, 11-1, 23, 17, 0), 
            extendedProps: [{
                theme: '外部領域における、半線形楕円型方程式に対する解の存在と非存在について',
                detail: '本講演では,全空間 Rn における半径 R の球の外部領域上での,半線形楕円型方程式 ∆u + K(r)f(u) = 0 の球対称解 (u(x) = u(|x|) を満たす解) について着目していきます.特に,R が十分大きいときの解の非存在について示すことが主な目標となります. * EJDE 2016 J.A.Iaia 氏の論文紹介',
                speaker: "間庭正明",
                archive: "https://www.youtube.com/embed/xRxpTocRr34",
                place: '台町市民センター（西八王子駅）'
            }],
        },
        {
            title:'数学専門セミナー(対面)', 
            start: new Date(2023, 12-1, 17, 13, 0), 
            end: new Date(2023, 12-1, 17, 17, 0), 
            extendedProps: [{
                theme: 'シャファレヴィッチ−テイト集合について',
                detail: '次の論説を紹介する。小野孝「シャファレヴィッチ−テイト集合について」別冊・数理科学2000年10月「数論の歩み　未解決問題への挑戦」( 元は1998年に早稲田大学で行われた国際集会「Class Field Theory-its Centenary and Prospect」における同氏の講演「On Shafarevich-Tate Sets」である。)なお、以下概要を述べるが、この論説に書いてある以上の知見は、講演者にはないことを断っておく。シャファレヴィッチ−テイト集合(以降ST集合)は、類体論やHasseの原理に触発されて思い付かれたもののようであるが、定義は群論の初歩の知識のみでできる。「あるクラスの有限群に対してはST集合はtrivialである」という小野孝氏の98年の結果の簡単な場合を証明まで含めて述べる。またtrivialにならない具体例も紹介する。群の定義から述べるため、必要な予備知識は集合の簡単な知識のみである。',
                speaker: "河野真士",
                archive: "https://www.youtube.com/embed/g7efxqYUZoU",
                place: '台町市民センター（西八王子駅）'
            }],
        },
        {
            title:'数学専門セミナー(対面)', 
            start: new Date(2024, 1-1, 14, 13, 0), 
            end: new Date(2024, 1-1, 14, 17, 0), 
            extendedProps: [{
                theme: '解無数の連立方程式の解法',
                detail: `大学１年次の必須項目として「解無数の連立方程式を解いてパラメタ表示する」というものがある。きちんと行基本変形して、移項して、パラメタ設定するもので、初級レベルの到達点とも言えるものである。川嶌俊雄氏の教科書(東京海洋大学越中島キャンパスでたまたま指定された教科書)に書かれてある「列基本変形による方法」を紹介する。この方法は、計算の手順がひとつ少なく(移項する必要がない)、さらに計算自体も「最後までしなくてよい」ため、さらに楽である。また、この方法による解は従来の方法による解と、「着目する文字が同じ」ならば、定数倍まで含めていつでも完全に一致することが最近講演者により証明された。そのことを話す。 (プレプリントを配布します。)もしかしたら明らかかもしれないため、多数の聴講者と忌憚ない意見を歓迎します。`,
                speaker: '河野真士',
                archive: '',
                place: '台町市民センター（西八王子駅）'
            },
            {
                theme: '超準解析における超実数の取り扱いについて',
                detail: `超準解析は無限に大きい数、小さい数を数学として厳密に定式化する学問体系であるが、それについて講演者が勉強した内容を発表する。本セミナーは、以下に示す資料での議論を講演者が再現する形で行われる。コーシー列を用いた実数の構成法を応用し、実数から無限大・無限小を含む「超実数」を構成したのち、その上で関数の連続性などの種々の定理が成り立つことを確認する。（以下の資料を参考に話を展開します：https://www.kurims.kyoto-u.ac.jp/~kenkyubu/kokai-koza/H29-isono.pdf）`,
                speaker: '神野和磨',
                archive: '',
                place: '台町市民センター（西八王子駅）'
            }],
        },
    ]

    const [selectedEvent, setSelectedEvent] = useState({})
    const [isEventModalOpen, setIsEventModalOpen] = useState(false)

    const [styleForFadeIn, setStyleForFadeIn] = useState({});

    const beforeFadeIn = {
        opacity: 0,
        transform: 'translateY(20px)',
    }
    const afterFadeIn = {
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'transform 1.5s ease-in-out, opacity 1.5s ease-in-out',
    }

    const [textRef, isVisibleText] = useInView('-20% 0px -20% 0px');
    const [linkRef, isVisibleLink] = useInView('-20% 0px -20% 0px');
    const [imageRef, isVisibleImage] = useInView('-20% 0px -20% 0px');
    const [notificationRef, isVisibleNotification] = useInView('-20% 0px -40% 0px');
    const [calenderRef, isVisibleCalender] = useInView('-20% 0px -40% 0px');
    const [staffRef, isVisibleStaff] = useInView('-20% 0px -40% 0px');

    const [textHasDisplayed, setTextHasDisplayed] = useState(false)
    const [linkHasDisplayed, setLinkHasDisplayed] = useState(false)
    const [imageHasDisplayed, setImageHasDisplayed] = useState(false)
    const [calenderHasDisplayed, setCalenderHasDisplayed] = useState(false)
    const [notificationHasDisplayed, setNotificationHasDisplayed] = useState(false)
    const [staffHasDisplayed, setStaffHasDisplayed] = useState(false)

    useEffect(()=>{
        !textHasDisplayed && setTextHasDisplayed(isVisibleText ? true : false)
        !linkHasDisplayed && setLinkHasDisplayed(isVisibleLink ? true : false)
        !imageHasDisplayed && setImageHasDisplayed(isVisibleImage ? true : false)
        !calenderHasDisplayed && setCalenderHasDisplayed(isVisibleCalender ? true : false)
        !notificationHasDisplayed && setNotificationHasDisplayed(isVisibleNotification ? true : false)
        !staffHasDisplayed && setStaffHasDisplayed(isVisibleStaff ? true : false)
    },[isVisibleText, isVisibleLink, isVisibleImage, isVisibleNotification, isVisibleCalender, isVisibleStaff])

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '70%' : '50%',
        bgcolor: 'background.paper',
        border: '2px solid grey',
        borderRadius: '10px',
        overflowY: 'auto', // 縦方向にスクロールバーを表示
        boxShadow: 24,
        height: isMobile ? '80vh' : (selectedEvent.extendedProps && selectedEvent.extendedProps[0].archive ? '90vh' : 'auto'), // 要素の高さをビューポートの80%に設定
        maxHeight: isMobile ? '80vh' : '90vh',
        p: 4,
        fontFamily: 'Shippori Mincho'
    };

    const [scrollPosition, setScrollPosition] = useState(null)

    const allDayDidMount = () => {
        // それまでのスクロール位置を取得
        let sp = document.querySelector('.fc-timegrid-slots').parentNode?.parentNode?.scrollTop
        setScrollPosition(sp) // 一応スクロール位置を保存
    }

    return (
        <div className="home">
                <div className='column'>
                    <div className='column1'>
                        <div className='column1-top' ref={textRef} style={{...(!textHasDisplayed && beforeFadeIn), ...(isVisibleText && afterFadeIn)}}>
                            {/* <h1>高尾数学研究所は<br></br>私設の研究機関です。</h1> */}
                            <h1 className='pc'>知の大海原へ、冒険に<br></br>出かけようではありませんか。</h1>
                            <h1 className='mobile'>知の大海原へ、<br></br>冒険に出かけよう<br></br>ではありませんか。</h1>
                            <br></br>
                            <h2 className='pc'>高尾数学研究所は私設の研究機関です。</h2>
                            <h2 className='mobile'>高尾数学研究所は<br></br>私設の研究機関です。</h2>
                            <br></br>
                            <h2 className='pc'>Liberal Arts*に関する研究者の自由な発表、交流<br></br>およびセミナー実施による相互の学びあいを目的として活動しています。</h2>
                            <h2 className='mobile'>Liberal Arts*に関する<br></br>研究者の自由な発表、交流および<br></br>セミナー実施による相互の学びあいを<br></br>目的として活動しています。</h2>
                            <h3>*Liberal Arts = 数学、文学、哲学、神学、音楽、理論物理学などの諸学問</h3>
                        </div>
                        {/* <div className='seminar-info-root'>
                            <div className='seminar-info'>
                                <h2>セミナー情報</h2>
                                <box className="seminar-box">
                                    {
                                        seminar.slice(-1).map(e => (
                                            <>
                                            <h3 className='date'><span>次回</span>{e.date}</h3>
                                            <h3 className='title'>演題：{e.firstTitle}<span>講演：{e.speaker}</span></h3>
                                            </>
                                        ))
                                    }
                                </box>
                            </div>
                            <p className='detail-to-click'>クリックして詳細を見る</p>
                        </div> */}
                    </div>
                    <div className='column2'>
                        {/* <div className="img" style={{backgroundImage: `url(${currentImage})`}}/> */}
                        <a className='to-contact' href="/contact" ref={linkRef} style={{...(!linkHasDisplayed && beforeFadeIn), ...(isVisibleLink && afterFadeIn)}}>セミナー聴講の<br></br>お問合せはこちらから</a>
                    </div>
                </div>
                <div className='image-flex'>
                    {/* <div className='image-flex' ref={imageRef} style={{...(!imageHasDisplayed && beforeFadeIn), ...(isVisibleImage && afterFadeIn)}}> */}
                    <ul>
                        {
                            [img_seminar_1, img_seminar_2, img_seminar_3].map(e => (
                                <div className='img'>
                                    <img src={e}></img>
                                </div>
                            ))
                        }
                    </ul>
                </div>
                <div className='home-middle'>
                    <div className='row'>
                        <div className='blog-box' ref={calenderRef} style={{...(!calenderHasDisplayed && beforeFadeIn), ...(isVisibleCalender && afterFadeIn)}}>
                            <h2 className='title'>イベント予定</h2>
                            <div>
                                {
                                    window.width < 450 ? 
                                        <FullCalendar
                                            plugins={[dayGridPlugin]}
                                            initialView="dayGridMonth"
                                            locales={[jaLocale]}         
                                            locale='ja'                  
                                            events={events}
                                            eventClick={(info) => {
                                                setSelectedEvent(info.event)
                                                setIsEventModalOpen(true)
                                            }}
                                            contentHeight={()=>{
                                                if(window.width < 450) {
                                                    return 600
                                                }
                                            }}
                                        /> :
                                    window.width < 900 ? 
                                    <FullCalendar
                                        plugins={[dayGridPlugin]}
                                        initialView="dayGridMonth"
                                        locales={[jaLocale]}         
                                        locale='ja'                  
                                        events={events}
                                        eventClick={(info) => {
                                            setSelectedEvent(info.event)
                                            setIsEventModalOpen(true)
                                        }}
                                        contentHeight={()=>{
                                            if(window.width < 900) {
                                                return 800
                                            }
                                        }}
                                    /> :
                                    window.width < 1200 ? 
                                    <FullCalendar
                                        plugins={[dayGridPlugin]}
                                        initialView="dayGridMonth"
                                        locales={[jaLocale]}         
                                        locale='ja'                  
                                        events={events}
                                        eventClick={(info) => {
                                            setSelectedEvent(info.event)
                                            setIsEventModalOpen(true)
                                        }}
                                        contentHeight={()=>{
                                            if(window.width < 1200) {
                                                return 1200
                                            }
                                        }}
                                        allDayDidMount={allDayDidMount} // scrollの設定をallDayDidMountで行う
                                        scrollTime={false}
                                    /> :
                                        <FullCalendar
                                            plugins={[dayGridPlugin]}
                                            initialView="dayGridMonth"
                                            locales={[jaLocale]}         
                                            locale='ja'                  
                                            events={events}
                                            eventClick={(info) => {
                                                setSelectedEvent(info.event)
                                                setIsEventModalOpen(true)
                                            }}
                                            allDayDidMount={allDayDidMount} // scrollの設定をallDayDidMountで行う
                                            scrollTime={false}
                                        />
                                }
                                {
                                    selectedEvent.start &&
                                    <Modal
                                    open={isEventModalOpen}
                                    onClose={()=>{setIsEventModalOpen(false)}}
                                    >
                                        <Box sx={modalStyle} className="modal-box">
                                            {
                                                isMobile ? 
                                                <>
                                                    <h2>{selectedEvent.title}</h2>
                                                    <h3>日時</h3>
                                                    <h3 className='content'>{`${selectedEvent.start.getFullYear()}年 ${selectedEvent.start.getMonth() + 1}月${selectedEvent.start.getDate()}日 ${selectedEvent.start.getHours()}時${('0'+selectedEvent.start.getMinutes()).slice(-2)}分`} 〜 {`${selectedEvent.end.getHours()}時${('0'+selectedEvent.end.getMinutes()).slice(-2)}分`}</h3>
                                                    <h3>場所</h3>
                                                    <h3 className='content'>{selectedEvent.extendedProps[0].place}</h3>
                                                    {
                                                        selectedEvent.extendedProps &&
                                                        Object.values(selectedEvent.extendedProps).map((props, idx, array) => (
                                                            <>
                                                                <h3>演題{array.length > 1 && idx+1}</h3>
                                                                <h3 className='content'>{!!props.theme ? props.theme : '未定'}</h3>
                                                                <h3>講演</h3>
                                                                <h3 className='content'>{!!props.speaker ? props.speaker : '未定'}</h3>
                                                                {
                                                                    props.archive && <div className='youtube'>
                                                                        <iframe src={props.archive} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                }
                                                                <p>{props.detail ?? '未定'}</p>
                                                            </>
                                                        ))
                                                    }
                                                </> : 
                                                <>
                                                    <h2>{selectedEvent.title}</h2>
                                                    <h3>日時</h3>
                                                    <h3 className='content'>{`${selectedEvent.start.getFullYear()}年 ${selectedEvent.start.getMonth() + 1}月${selectedEvent.start.getDate()}日 ${selectedEvent.start.getHours()}時${('0'+selectedEvent.start.getMinutes()).slice(-2)}分`} 〜 {`${selectedEvent.end.getHours()}時${('0'+selectedEvent.end.getMinutes()).slice(-2)}分`}</h3>
                                                    <h3>場所</h3>
                                                    <h3 className='content'>{selectedEvent.extendedProps[0].place}</h3>
                                                    {
                                                        selectedEvent.extendedProps &&
                                                        Object.values(selectedEvent.extendedProps).map((props, idx, array) => (
                                                            <>
                                                            <h3>演題{array.length > 1 && idx+1}：{!!props.theme ? props.theme : '未定'}</h3>
                                                            <h3>講演：{!!props.speaker ? props.speaker : '未定'}</h3>
                                                            {/* <h3>日時：{`${props.getFullYear()}年 ${props.getMonth() + 1}月${props.getDate()}日 ${props.getHours()}時${('0'+props.getMinutes()).slice(-2)}分`} 〜 {`${props.getHours()}時${('0'+props.getMinutes()).slice(-2)}分`}</h3>
                                                            <h3>場所：{props.place}</h3> */}
                                                            {
                                                                props.archive && <div className='youtube'>
                                                                    <iframe src={props.archive} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                </div>
                                                            }
                                                            <p>{props.detail ?? '未定'}</p>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                            }
                                        </Box>
                                    </Modal>
                                }
                            </div>
                        </div>
                        <div className='notification-box' ref={notificationRef} style={{...(!notificationHasDisplayed && beforeFadeIn), ...(isVisibleNotification && afterFadeIn)}}>
                            <h2>お知らせ</h2>
                            <ul>
                            {
                                notification.map(e => (
                                    <li>
                                        <p className='left'>{e.date.toLocaleDateString()}</p><p className='right'>{e.title}</p>
                                    </li>
                                ))
                            }
                            </ul>
                        </div>
                    </div>
                    <div className='image-flex'>
                        {/* <div className='image-flex' ref={imageRef} style={{...(!imageHasDisplayed && beforeFadeIn), ...(isVisibleImage && afterFadeIn)}}> */}
                        <ul>
                            {
                                [img_seminar_4, img_seminar_5, img_seminar_6].map(e => (
                                    <div className='img'>
                                        <img src={e}></img>
                                    </div>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='staff-box' ref={staffRef} style={{...(!staffHasDisplayed && beforeFadeIn), ...(isVisibleStaff && afterFadeIn)}}>
                        <h2>研究員紹介</h2>
                        <div className='staff-list'>
                            {
                                staff.map(e => (
                                    <div className='staff-member'>
                                        <div className='img-wrap'>
                                            <img src={e.img}/>
                                        </div>
                                        <div className='profile'>
                                            <h3>{e.name}</h3>
                                            <h4>{e.major}</h4>
                                            <h4>{e.title}</h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
        </div>
    )
}