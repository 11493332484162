import React from 'react';
import '../css/Seminar.css';

export const Seminar = () => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent) && window.orientation == 0;

    const seminar = [
        {
            "firstTitle": "半線形楕円型方程式の解構造について",
            "firstDetail": "無料です。興味のある方はどなたでも聴講できます。講演者募集。広く、数学、理論物理学、経済学、哲学などについて。最新の研究発表のみでなく、論文紹介や、学部後半の講義レベルでも構いません。仲間になりましょう！！",
            "secondTitle": "第1回 Nothing Seminar",
            "secondDetail": "ひも理論とは、包摂的な教育に関してのディスカッション、数学パズル",
            "date": "<span>10</span>月<span>9</span>日（月・祝）",
            "speaker": "河野真士",
            "archive": "https://youtu.be/Y7_Q3i02254"
        },
        {
            "firstTitle": "向き付け可能な閉曲面の写像類群の表示",
            "firstDetail": "種数gの閉曲面をg個の円周で切り開くと球面に2g個の穴を開けたものになりますが、このことと写像類群の元の円周への作用、およびある「単純な動き」に着目することで、写像類群の有限表示を得ることができます。<br/>(A.Hatcher and W.Thurstonの論文紹介を予定しています。)",
            "caption": "* 可微分多様体の自己同相写像のイソトピー類のなす群を写像類群と言い、 曲面の場合はとくに（タイヒミュラー）モジュラー群とも呼びます。",
            "secondTitle": "第2回 Nothing Seminar",
            "secondDetail": "長恨歌素読、大江健三郎「『自分の木』の下で」を読んで学校の意義について考える",
            "date": "<span>11</span>月<span>3</span>日（金・祝）",
            "speaker": "佐々木和美",
            "archive": "https://youtu.be/sWxx84I0HtA"
        },
        {
            "firstTitle": "外部領域における,半線形楕円型方程式に対する解の存在と非存在について",
            "firstDetail": "本講演では,全空間 Rn における半径 R の球の外部領域上での,半線形楕円型方程式 ∆u + K(r)f(u) = 0 の球対称解 (u(x) = u(|x|) を満たす解) について着目していきます.特に,R が十分大きいときの解の非存在について示すことが主な目標となります.",
            "caption": "* EJDE 2016 J.A.Iaia 氏の論文紹介",
            "secondTitle": "第3回 Nothing Seminar",
            "secondDetail": "1+1=1について考える、経路の分割と無限についての考察",
            "date": "<span>11</span>月<span>23</span>日（木・祝）",
            "speaker": "間庭正明",
            "archive": "https://youtu.be/xRxpTocRr34"
        },
        {
            "firstTitle": "シャファレヴィッチ−テイト集合について",
            "firstDetail": "次の論説を紹介する。小野孝「シャファレヴィッチ−テイト集合について」別冊・数理科学2000年10月「数論の歩み　未解決問題への挑戦」( 元は1998年に早稲田大学で行われた国際集会「Class Field Theory-its Centenary and Prospect」における同氏の講演「On Shafarevich-Tate Sets」である。)なお、以下概要を述べるが、この論説に書いてある以上の知見は、講演者にはないことを断っておく。シャファレヴィッチ−テイト集合(以降ST集合)は、類体論やHasseの原理に触発されて思い付かれたもののようであるが、定義は群論の初歩の知識のみでできる。「あるクラスの有限群に対してはST集合はtrivialである」という小野孝氏の98年の結果の簡単な場合を証明まで含めて述べる。またtrivialにならない具体例も紹介する。群の定義から述べるため、必要な予備知識は集合の簡単な知識のみである。",
            "secondTitle": "第4回 Nothing Seminar",
            "secondDetail": "アミノ酸のL体・D体とホモキラリティー、環状化合物の安定性について、cosとsin、ベクトルの基礎",
            "date": "<span>12</span>月<span>17</span>日（月・祝）",
            "speaker": "河野真士",
            "archive": "https://youtu.be/g7efxqYUZoU"
        },
    ]

    return (
        <div className='seminar'>
                <h1>過去の活動</h1>
                <h2>
                    高尾数学研究所は、毎月セミナーを行なっています。<br></br>
                    みなさまのご参加を、お待ちしております！
                </h2>
                <h4>前半「数学専門セミナー」</h4>
                <p> 広く、数学、理論物理学、経済学、哲学などについて。最新の研究発表のみでなく、論文紹介や、学部後半の講義レベルも扱います。興味のある方はどなたでも聴講できます。講演者希望の方も歓迎します。仲間になりましょう！</p>
                <h4>後半「Nothing Seminar」</h4>
                <p>本研究所で最も大切にしている教育用セミナーです。年齢や身分やレベルに関係なく、勉強していることの成果報告、古典素読、輪読、質疑応答など、を行います。原則的に「受講者側の発信」から始まります。幼稚園レベルから大学院レベルまで、年齢に関係なく学びます。そこから発展的に説明したりすることがあります。</p>

                <h3>過去のセミナー</h3>
                <ul>
                    {
                        seminar.reverse().map(e => (
                            <li>
                                <a>
                                <h4 dangerouslySetInnerHTML={{ __html: e.date }}/>
                                <div className='flex'>
                                    <p style={{width: !isMobile && '25%'}}>数学専門セミナー</p>
                                    <div style={{width: !isMobile && '75%'}}>
                                        <p style={{fontWeight: 'bold'}}>{e.firstTitle}（講演: {e.speaker}）</p>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <p style={{width: !isMobile && '25%'}}>{e.secondTitle}</p>
                                    <div style={{width: !isMobile && '75%'}}>
                                        <p style={{fontWeight: 'bold'}}>{e.secondDetail}</p>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <p style={{width: !isMobile && '25%'}}>アーカイブ</p>
                                    <div style={{width: !isMobile && '75%', textAlign: 'left'}}>
                                        <a href={e.archive} target='blank'>{e.archive}</a>
                                    </div>
                                </div>
                                </a>
                            </li>
                        ))
                    }
                </ul>
        </div>
    )
}