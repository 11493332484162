import './App.css';
import { useEffect } from 'react';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { About ,Home ,Staff ,Course, Contact, Application, Faq } from './components/pages';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Seminar } from './components/pages/Seminar';
import Blog from './components/pages/Blog';
import BlogArticle from './components/pages/BlogArticle';

function ScrollTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const handleResize = () => {
    window.location.reload();
  };
  
  useEffect(() => {
    window.addEventListener('orientationchange', handleResize);

    return () => {
        window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Navbar />
      <div className='main'>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home/>} />
        {/* <Route path="/about" element={<About/>} /> */}
        <Route path="/member" element={<Staff/>} />
        <Route path="/seminar" element={<Seminar/>} />
        <Route path="/course" element={<Course/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/blog" element={<Blog />}/>
        <Route path="/blog/:id" element={<BlogArticle />} />
        {/* <Route path="/faq" element={<Faq/>} /> */}
      </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
